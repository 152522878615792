import { IWixAPI } from '@wix/yoshi-flow-editor';

export const MEMBERS_PROFILE_WIDGET_ID =
  '14dbefb9-3b7b-c4e9-53e8-766defd30587' as const;
const MEMBERS_INFO_SECTION_ID = 'member_info';
const MEMBERS_INFO_APP_ID = '14cffd81-5215-0a7f-22f8-074b0e2401fb';
const MEMBERS_AREA_V2_APP_ID = 'b976560c-3122-4351-878f-453f337b7245';
const MEMBERS_AREA_V2_SECTION_ID = 'member_page';

export const isMemberAreaInstalled = async (wixCodeApi: IWixAPI) =>
  (
    await Promise.all([
      wixCodeApi.site.isAppSectionInstalled({
        sectionId: MEMBERS_INFO_SECTION_ID,
        appDefinitionId: MEMBERS_INFO_APP_ID,
      }),
      wixCodeApi.site.isAppSectionInstalled({
        sectionId: MEMBERS_AREA_V2_SECTION_ID,
        appDefinitionId: MEMBERS_AREA_V2_APP_ID,
      }),
    ])
  ).find(Boolean);
