import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';

export const model = {
  props: {
    categoriesToDisplay: {
      type: WidgetPropertyType.STRING,
      description: 'Categories to display',
    },
    hostWidget: {
      type: WidgetPropertyType.STRING,
      description: 'Host widget wrapper',
    },
  },
  methods: {
    initialize: {
      description: 'Initialize the post categories',
    },
  },
  events: {},
};

export default createBlocksModel({ widgetName: 'Post Categories', ...model });
