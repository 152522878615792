import { TagCloudView } from '../types';
import { tagCloudWidgetSuccess } from './tag-cloud.actions';

type TagCloudWidgetState = Partial<TagCloudView>;

type AnyAction = ReturnType<typeof tagCloudWidgetSuccess>;

const tagCloudWidgetReducer = (
  state: TagCloudWidgetState = {},
  action: AnyAction,
) => {
  switch (action.type) {
    case tagCloudWidgetSuccess.type:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default tagCloudWidgetReducer;
