import { getIsPostRatingEnabled } from '../../../common/selectors/app-settings-selectors';
import { getPostBySlug } from '../../../common/selectors/post-selectors';
import { updatePostsRatings } from '../../../common/store/post-ratings/post-ratings-actions';
import type {
  AppStore,
  ControllerConfig,
  FlowAPI,
} from '../../../common/types';

type Params = {
  controllerConfig: ControllerConfig;
  store: AppStore;
  flowAPI: FlowAPI;
  loadSSR?: boolean;
};

export async function initWixCommentsController({
  controllerConfig,
  store,
  flowAPI,
  loadSSR,
}: Params) {
  const initializeCommentsController = (
    await import(
      /* webpackChunkName: "wix-comments-controller" */ './wix-comments-controller'
    )
  ).default;

  const wixCommentsApi = await initializeCommentsController({
    controllerConfig,
    httpClient: flowAPI.httpClient,
    useRicosNext: true,
    loadSSR: !!loadSSR,
  });

  if (loadSSR) {
    const state = store.getState();
    const post = getPostBySlug(
      state,
      controllerConfig.wixCodeApi.location.path[1], // We are passing resolved wixCodeApi from create-controller.ts
    );
    const resourceId = post?.internalId || undefined;

    if (!resourceId) {
      return wixCommentsApi;
    }

    // fetchComments returns a promise (not void).
    (await wixCommentsApi.fetchComments({
      resourceId,
      ctxFields: {
        contextId: resourceId,
        contextType: 'postId',
      },
    })) as unknown as Promise<void>;

    // bindStateToSetProps returns a promise (not void).
    (await wixCommentsApi.bindStateToSetProps()) as unknown as Promise<void>;

    if (getIsPostRatingEnabled(state)) {
      wixCommentsApi.watch.ratings.onChange((newState) => {
        store.dispatch(updatePostsRatings(newState));
      });
    }
  } else {
    const state = store.getState();
    const withRatings = getIsPostRatingEnabled(state);

    if (withRatings) {
      wixCommentsApi.watch.ratings.onChange((newState) => {
        store.dispatch(updatePostsRatings(newState));
      });
    }
  }

  return wixCommentsApi;
}
