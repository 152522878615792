import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_POST_METADATA_SUCCESS = 'postMetadata/FETCH_SUCCESS';

export const fetchPostMetadataRequest = createAction(
  'postMetadata/FETCH_REQUEST',
);
export const fetchPostMetadataSuccess = createAction(
  FETCH_POST_METADATA_SUCCESS,
);
const fetchPostMetadataFailure = createAction('postMetadata/FETCH_FAILURE');

export const fetchPostMetadata = (postId) => {
  return (dispatch, _, { request }) => {
    dispatch(fetchPostMetadataRequest({ postId }));

    const promise = request(`/_api/posts/metadata/${postId}`);
    return promise
      .then((postMetadata) => dispatch(fetchPostMetadataSuccess(postMetadata)))
      .then(() => promise)
      .catch(() => dispatch(fetchPostMetadataFailure()));
  };
};
