import { compact } from 'lodash';

import {
  MODAL_TYPE_POST_EDITOR,
  MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS,
  getCurrentUser,
  getCurrentUserSiteMemberId,
} from '@wix/communities-blog-client-common';
import { fetchPost } from '../../common/actions/fetch-post';
import { openModal } from '../../common/modals/framework/store/modal-actions';
import createPermissionChecker from '../../common/services/create-permission-checker';
import createPermissionHelpers from '../../common/services/create-permission-helpers';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import fetchCategories from '../../common/store/categories/fetch-categories';
import { setPostEditorPost } from '../../common/store/post-editor/post-editor-actions';
import { fetchWriters } from '../../common/store/writers/writers-actions';
import { fetchRceSettings } from '../actions/rce-settings';

const POST_LOCK_MINUTES = 10;
const MS_PER_MINUTE = 60000;

const isAfter = (date1, date2) =>
  Boolean(date1) && new Date(date1) > new Date(date2);

const isAnotherWriterEditingThePost = (post = {}, currentUserSiteMemberId) => {
  if (!post.lastWriterSiteMemberId) {
    return false;
  }

  if (currentUserSiteMemberId === post.lastWriterSiteMemberId) {
    return false;
  }

  const now = new Date();
  const lockedAt = new Date(now - POST_LOCK_MINUTES * MS_PER_MINUTE);
  const editedAt = 'draft' in post ? post.draft?.editedDate : post?.editedDate;

  const isLocked = isAfter(editedAt, lockedAt);
  const isEditedAfterPublishDate = !isAfter(post.lastPublishedDate, editedAt);

  return isLocked && isEditedAfterPublishDate;
};

export const fetchInitialPostEditorData = (state, dispatch, postSlugOrId) => {
  const { can } = createPermissionHelpers(
    createPermissionChecker(state, getCurrentUser(state)),
  );

  const initialData = compact([
    dispatch(fetchRceSettings()),
    dispatch(fetchCategories()),
    can('change-owner', 'post') && dispatch(fetchWriters()),
    postSlugOrId
      ? dispatch(fetchPost(postSlugOrId, { includeDraft: true })).then((post) =>
          tryEditPost(state, dispatch, post),
        )
      : initPostEditor(state, dispatch),
  ]);

  return Promise.all(initialData);
};

const tryEditPost = (state, dispatch, post) => {
  const anotherWriterEditingPost = isAnotherWriterEditingThePost(
    post,
    getCurrentUserSiteMemberId(state),
  );
  if (anotherWriterEditingPost) {
    return dispatch(
      openModal(MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS, { postSlug: post.slug }),
    );
  }
  return initPostEditor(state, dispatch, post);
};

const initPostEditor = (state, dispatch, post) => {
  const isMobile = getIsMobile(state);
  if (isMobile) {
    dispatch(setPostEditorPost(post));
    return dispatch(openModal(MODAL_TYPE_POST_EDITOR));
  }
  return dispatch(setPostEditorPost(post));
};
