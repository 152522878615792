import type { Dispatch } from 'redux';
import type { IWixAPI } from '@wix/yoshi-flow-editor';
import {
  routerDataServiceFactory,
  type IMembersAreaWidgetPluginService,
} from '@wix/members-area-widget-plugin-lib/viewer';
import { createAction } from '@wix/communities-blog-client-common';
import getEnvironment from '../../../common/services/get-environment';
import type { GetState } from '../../../common/types';

export const setViewedUser = createAction('viewedUser/SET');

export const fetchViewedUser =
  (widgetPluginService: IMembersAreaWidgetPluginService) =>
  async (
    dispatch: Dispatch,
    getState: GetState,
    { wixCodeApi }: { wixCodeApi: IWixAPI },
  ) => {
    const { isPreview, isEditor } = getEnvironment(wixCodeApi);

    const memberIds = await getMemberIds(wixCodeApi, widgetPluginService);
    const userId =
      isEditor || isPreview
        ? memberIds.currentMemberId
        : memberIds.viewedMemberId;
    dispatch(setViewedUser(userId));
  };

const getMemberIds = async (
  wixCodeApi: IWixAPI,
  widgetPluginService: IMembersAreaWidgetPluginService,
) => {
  const { window, user } = wixCodeApi;
  const routerDataService = routerDataServiceFactory(
    widgetPluginService,
    user,
    window,
  );

  const routeData = await routerDataService.getRouteData();

  const currentMemberId = routeData?.currentMemberIdentifier?.id ?? null;
  const viewedMemberId = routeData?.viewedMemberIdentifier?.id ?? null;

  return { currentMemberId, viewedMemberId };
};
