import { CounterController } from './counter-controller';
import { selectors } from './elements';
import model from './model';
import type { ControllerParams } from './types';

const createController = model.createController((controller) => {
  const widget = controller.$widget;

  return {
    pageReady: ($w) => {
      if (widget.props.isCommentsPluginInstalled) {
        $w(selectors.commentsContainer).restore();
      } else {
        $w(selectors.commentsContainer).delete();
      }

      widget.onPropsChanged((_, newProps) => {
        if (newProps.isCommentsPluginInstalled) {
          $w(selectors.commentsContainer).restore();
        } else {
          $w(selectors.commentsContainer).delete();
        }
      });
    },
    exports: new CounterController(controller as ControllerParams),
  };
});

export default createController;
