import {
  isDraftContent,
  isRichContent,
  extractMedia as extractMediaUrlsFromDraftJS,
} from '@wix/ricos';
import { extractMediaUrls as extractMediaUrlsFromRichContent } from '../../blocks-widget/helpers/extract-media-urls';

export const extractMediaUrlsFromDraftOrRichContent = (
  content: Record<string, any>,
): string[] | undefined => {
  if (isDraftContent(content)) {
    return extractMediaUrlsFromDraftJS(content).map(
      (media) => media.imageUrl || media.videoThumbnailUrl,
    );
  }

  if (isRichContent(content)) {
    return extractMediaUrlsFromRichContent(content.nodes);
  }
};
