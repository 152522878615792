import { FETCH_RELATED_POSTS_SUCCESS } from '../actions/fetch-related-posts';

export default function relatedPostsIdsReducer(
  state = {},
  { type, payload, meta } = {},
) {
  switch (type) {
    case FETCH_RELATED_POSTS_SUCCESS:
      return { ...state, [meta.postId]: payload };
    default:
      return state;
  }
}
