import {
  BLOG_APP_ID,
  BLOG_POST_PAGE_SECTION_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import { REFERRAL_BLOG_POST_PAYWALL } from '../../common/constants/paid-plans';

interface Params {
  pricingPlanIds: string[] | undefined;
  slug: string | undefined;
  id: string | undefined;
}

export const getPricingPlansOptions = ({
  pricingPlanIds,
  slug,
  id,
}: Params) => ({
  planIds: pricingPlanIds,
  checkout: {
    thankYouPage: {
      content: {
        title: 'Thanks for Subscribing',
        message:
          'A confirmation email is on its way to you.\nNow you can get back to your post!',
        cta: 'Keep Reading',
      },
      navigation: slug
        ? {
            type: 'section' as const,
            options: {
              sectionId: BLOG_POST_PAGE_SECTION_ID,
              appDefinitionId: BLOG_APP_ID,
              state: slug,
            },
          }
        : undefined,
    },
  },
  biOptions: {
    referralInfo: REFERRAL_BLOG_POST_PAYWALL,
    referralId: id,
  },
});
