import type { InitAppForPageFn } from '@wix/yoshi-flow-editor';
import { LocationAwareStore } from './blocks/location-aware-store';
import { makeSubjectsForAppData } from './blocks/pub-sub';
import type { PostPageRenderModel } from './external/blocks-widget/post-page-render-model';
import { NormalizedPost } from './external/common/types';
import { fetchInitialData } from './viewer/init';

export const initAppForPage = (async (
  initParams,
  platformApis,
  wixCodeApi,
  _,
  flowAPI,
) => {
  const postPageStore = new LocationAwareStore<PostPageRenderModel>({
    wixCodeApi,
    dataFn: () =>
      fetchInitialData({
        wixCodeApi,
        instanceId: initParams.instanceId,
        flowAPI,
      }),
  });

  return {
    subjects: {
      ...makeSubjectsForAppData(platformApis),

      /**
       * IMPORTANT: If used in `pageReady()`, must use `await` to ensure
       * data is properly fetched during SSR & CSR.
       *
       * @example
       * ```ts
       * await appData.subjects.postPageRenderModel.subscribe((data) => {
       *  // do something with data
       * });
       * ```
       **/
      postPageRenderModel: {
        subscribe: (handler: (model: PostPageRenderModel) => void) =>
          postPageStore.subscribe(handler),
      },
    },
    /**
     * Used for Post Page public API
     *
     * @see `Post Page/viewer.controller.ts#exports` */
    fetchPublicPostPageRenderModel: async (): Promise<NormalizedPost> => {
      const { post } = await fetchInitialData({
        wixCodeApi,
        instanceId: initParams.instanceId,
        flowAPI,
      });

      return post;
    },
  };
}) satisfies InitAppForPageFn;

export type AppData = Awaited<ReturnType<typeof initAppForPage>>;
