import { IWixAPI, ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPostFeedMetadataPage } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import { getPostPageMetadata } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import { isEditorOrPreview } from './selectors';

interface FetchMetadataParams {
  flowAPI: ViewerScriptFlowAPI;
  postSlug?: string;
  postId?: string;
  wixCodeApi: IWixAPI;
}

interface MetadataResponse {
  viewCount: number;
  likeCount: number;
  totalComments: number;
  averageRating: number;
  totalRatings: number;
}

export const fetchMetadata = async ({
  flowAPI,
  postSlug,
  postId,
  wixCodeApi,
}: FetchMetadataParams): Promise<MetadataResponse> => {
  try {
    if (!postSlug) {
      throw new Error('No post slug provided');
    }

    if (isEditorOrPreview(wixCodeApi)) {
      const {
        data: { postFeedMetadataPage },
      } = await flowAPI.httpClient.request(
        getPostFeedMetadataPage({
          page: 1,
          pageSize: 1,
          languageCode: flowAPI.environment.language,
        }),
      );

      const metaDataFromFeed =
        postFeedMetadataPage?.postMetrics?.[postId as string];

      return {
        viewCount: metaDataFromFeed?.views ?? 0,
        likeCount: metaDataFromFeed?.likes ?? 0,
        totalComments: metaDataFromFeed?.comments ?? 0,
        averageRating: metaDataFromFeed?.averageRating ?? 0,
        totalRatings: metaDataFromFeed?.totalRatings ?? 0,
      };
    }

    const {
      data: { postPageMetadata },
    } = await flowAPI.httpClient.request(
      getPostPageMetadata({
        postId: postSlug,
        skipViewCountIncrement: true,
        languageCode: flowAPI.environment.language,
      }),
    );

    return postPageMetadata?.postMetadata as MetadataResponse;
  } catch (err) {
    if (err instanceof Error) {
      flowAPI.reportError(err);
    }
    return {
      averageRating: 0,
      likeCount: 0,
      totalComments: 0,
      totalRatings: 0,
      viewCount: 0,
    };
  }
};
