import { createAction } from '@wix/communities-blog-client-common';
import { NormalizedPost } from '../types';

export const FETCH_CATEGORY_POSTS_REQUEST = 'categoryPosts/FETCH_REQUEST';
export const FETCH_CATEGORY_POSTS_SUCCESS = 'categoryPosts/FETCH_SUCCESS';
export const FETCH_CATEGORY_POSTS_FAILURE = 'categoryPosts/FETCH_FAILURE';

export const fetchCategoryPostsRequest = createAction(
  FETCH_CATEGORY_POSTS_REQUEST,
);
export const fetchCategoryPostsSuccess = createAction(
  FETCH_CATEGORY_POSTS_SUCCESS,
  (
    payload: NormalizedPost[],
    meta: {
      categoryId: string;
      page: number;
      entityCount: number;
      pageSize: number;
      cursor?: string;
    },
  ) => payload,
  (payload, meta) => meta,
);
export const fetchCategoryPostsFailure = createAction(
  FETCH_CATEGORY_POSTS_FAILURE,
  (payload, meta) => payload,
  (payload, meta) => meta,
);
