import { SET_READING_SESSION_ID } from '../actions/reading-session-id-actions';

export default function readingSessionIdReducer(
  state = '',
  { type, payload } = {},
) {
  switch (type) {
    case SET_READING_SESSION_ID:
      return payload;
    default:
      return state;
  }
}
