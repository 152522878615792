import { get } from 'lodash';
import { POST_PUBLISHED } from '../../common/actions/blog-socket';
import { FETCH_LAST_POST_SUCCESS } from '../actions/fetch-last-post';

export default function lastPostSlugReducer(state = '', action) {
  switch (action.type) {
    case FETCH_LAST_POST_SUCCESS:
    case POST_PUBLISHED:
      return get(action.payload, 'slug', '');

    default:
      return state;
  }
}
