import { SET_PROFILE_URLS } from './profile-urls-actions';

const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PROFILE_URLS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
