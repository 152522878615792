import { createAction } from '@wix/communities-blog-client-common';

export const DELETE_POST_PERMANENTLY_SUCCESS =
  'post/DELETE_PERMANENTLY_SUCCESS';
export const DELETE_POST_PERMANENTLY_FAILURE =
  'post/DELETE_PERMANENTLY_FAILURE';

export const deletePostPermanentlyRequest = createAction(
  'post/DELETE_PERMANENTLY_REQUEST',
);
export const deletePostPermanentlySuccess = createAction(
  DELETE_POST_PERMANENTLY_SUCCESS,
);
export const deletePostPermanentlyFailure = createAction(
  DELETE_POST_PERMANENTLY_FAILURE,
);

export function deletePostPermanently(postId, skipSuccess) {
  return (dispatch, getState, { request }) => {
    dispatch(deletePostPermanentlyRequest(postId));
    const promise = request.delete(`/_api/deleted-posts/${postId}`);

    return promise
      .then(() =>
        skipSuccess
          ? undefined
          : dispatch(deletePostPermanentlySuccess(postId)),
      )
      .catch(() => dispatch(deletePostPermanentlyFailure(postId)))
      .then(() => promise);
  };
}
