import { WAIT_FOR_ACTION } from './index';

export function waitForActionMiddleware(watchTypes, { isDebug } = {}) {
  const watching = watchTypes.reduce((acc, x) => (acc[x] = true) && acc, {});
  const seen = {};
  let pendingActions = [];
  const throwError = (x) => {
    throw new Error(`${x} is not watched`);
  };
  const log = isDebug ? console.log : () => {};

  const logWaiting = (action) => {
    if (isDebug) {
      const wait = action.payload.waitFor.filter((x) => !seen[x]).join(',');
      log('## waiting for', wait);
    }
  };

  return ({ dispatch }) => {
    const dispatchWaiting = ({
      action,
      resolve,
      reject,
      invokeBeforeDispatch,
    }) => {
      const act = dispatch(invokeBeforeDispatch ? action() : action);
      log('## dispatch waiting', act?.type);
      return Promise.resolve(act).then(resolve).catch(reject);
    };

    return (next) => (action) => {
      const type =
        action.type && action.type.replace('FETCH_FAILED', 'FETCH_SUCCESS');
      if (type === WAIT_FOR_ACTION) {
        if (
          action.payload.waitFor.every((x) =>
            watching[x] ? seen[x] : throwError(x),
          )
        ) {
          dispatchWaiting(action.payload);
        } else {
          pendingActions.push(action);
        }
        return null;
      } else if (type && watching[type]) {
        log('## got', type);
        const result = next(action);
        seen[type] = true;
        if (pendingActions.length) {
          const queue = [...pendingActions];
          pendingActions = [];
          for (const _action of queue) {
            _action.payload.waitFor.every((x) => seen[x])
              ? dispatch(_action)
              : logWaiting(_action) || pendingActions.push(_action);
          }
        }
        return result;
      }
      return next(action);
    };
  };
}
