import { getPostFeedPage } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import {
  createAction,
  ENTITY_TYPE_POSTS,
} from '@wix/communities-blog-client-common';
import { getLanguageCode } from '../selectors/locale-selectors';
import { getPageSize } from '../selectors/pagination-selectors';
import { getDemoPosts } from '../services/demo-posts';
import getEnvironment from '../services/get-environment';
import { getTotalResults } from '../services/pagination';
import { normalizePosts, normalizePostV3 } from '../services/post-utils';
import { getCategoryIds } from '../store/categories/categories-selectors';

export const FETCH_FEED_POSTS_REQUEST = 'feedPosts/FETCH_REQUEST';
export const FETCH_FEED_POSTS_SUCCESS = 'feedPosts/FETCH_SUCCESS';
export const FETCH_FEED_POSTS_FAILURE = 'feedPosts/FETCH_FAILURE';

export const fetchFeedPostsRequest = createAction(FETCH_FEED_POSTS_REQUEST);
export const fetchFeedPostsSuccess = createAction(
  FETCH_FEED_POSTS_SUCCESS,
  (payload, meta) => payload,
  (payload, meta) => meta,
);
export const fetchFeedPostsFailure = createAction(FETCH_FEED_POSTS_FAILURE);

export default function fetchFeedPosts({
  page = 1,
  pageSize: defaultPageSize,
  entityType = ENTITY_TYPE_POSTS,
  section,
  preFetch = false,
} = {}) {
  return (dispatch, getState, { wixCodeApi, httpClient }) => {
    const state = getState();

    dispatch(fetchFeedPostsRequest({ entityType, page }));

    const pageSize = getPageSize(getState(), {
      overrideSettingsPageSize: defaultPageSize,
      section,
    });

    const promise = httpClient
      .request(
        getPostFeedPage({
          languageCode: getLanguageCode(state),
          page,
          pageSize,
          includeContent: true,
        }),
      )
      .then(({ data, headers }) => {
        return {
          body: data.postFeedPage.posts.posts.map(normalizePostV3),
          headers,
        };
      });

    return preFetch
      ? promise
      : completeFetchFeedPosts({ entityType, page, pageSize }, promise)(
          dispatch,
          getState,
          { wixCodeApi, httpClient },
        );
  };
}

const completeFetchFeedPosts =
  ({ entityType = ENTITY_TYPE_POSTS, page, pageSize }, promise) =>
  async (dispatch, getState, { wixCodeApi, httpClient }) => {
    try {
      let { body = [], headers = {} } = await promise;
      if (!body.length && getEnvironment(wixCodeApi).isEditorSegment) {
        const fake = await getDemoPosts({
          httpClient,
          getState,
          dispatch,
          wixCodeApi,
          query: { page, pageSize },
        });
        body = fake.posts;
        headers = fake.headers;
        promise = Promise.resolve({ body, headers });
      }
      dispatch(
        fetchFeedPostsSuccess(
          normalizePosts({
            state: getState(),
            posts: body,
            blogCategoryIds: getCategoryIds(getState()),
          }),
          {
            page,
            entityType,
            entityCount: getTotalResults(headers),
            pageSize,
          },
        ),
      );
    } catch (_) {
      dispatch(fetchFeedPostsFailure({ entityType, page }));
    }
    return promise;
  };
