import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_HASHTAG_POSTS_REQUEST = 'hashtag/FETCH_POSTS_REQUEST';
export const FETCH_HASHTAG_POSTS_SUCCESS = 'hashtag/FETCH_POSTS_SUCCESS';
export const FETCH_HASHTAG_POSTS_FAILURE = 'hashtag/FETCH_POSTS_FAILURE';

export const fetchHashtagPostsRequest = createAction(
  FETCH_HASHTAG_POSTS_REQUEST,
);
export const fetchHashtagPostsFailure = createAction(
  FETCH_HASHTAG_POSTS_FAILURE,
);
export const fetchHashtagPostsSuccess = createAction(
  FETCH_HASHTAG_POSTS_SUCCESS,
  (payload, meta) => payload,
  (payload, meta) => meta,
);
