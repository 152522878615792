import { POST_PAGE_MORE_BUTTON_CLICKED } from '../../actions/post-page-more-button-clicked';
import { getQueryLocale } from '../../selectors/locale-selectors';
import { getPostByIdOrSlug } from '../../selectors/post-selectors';

const handler = ({ payload }, state) => {
  const post = getPostByIdOrSlug(state, payload.postId);
  const language = getQueryLocale(state);

  return {
    evid: 240,
    language,
    translation_id: post.translationId,
    is_demo: post.isDemo,
    eventMeta: {
      description:
        'Triggered when the user clicks on the 3 dots menu, on the post page',
    },
  };
};

export const uouPostPageMoreButtonClickedEvent = {
  [POST_PAGE_MORE_BUTTON_CLICKED]: handler,
};
