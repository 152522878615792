import {
  IWidgetControllerConfig,
  IWixWindow,
  ISiteApis,
  ILocation,
  IUserApis,
  ISeo,
} from '@wix/native-components-infra/dist/src/types/types';

export type IWixCodeApi = {
  location: ILocation;
  seo: ISeo;
  site: ISiteApis;
  user: IUserApis;
  window: IWixWindow;
};

export const getWixCodeApi = async (
  platformApiProvider: IWidgetControllerConfig['platformApiProvider'],
): Promise<IWixCodeApi> => {
  const apis = ['location', 'seo', 'site', 'user', 'window', 'authentication'];
  const apiInstances = await Promise.all(
    apis.map((api) => platformApiProvider.getPlatformApi(api)),
  );
  return Object.fromEntries(
    apis.map((api, index) => [api, apiInstances[index]]),
  ) as IWixCodeApi;
};
