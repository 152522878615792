import {
  HEADER_SEARCH_CLOSED,
  HEADER_SEARCH_OPENED,
} from '../../actions/header-search';

const initialState = { inputWidth: 0 };

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case HEADER_SEARCH_OPENED:
      return { inputWidth: 200 };
    case HEADER_SEARCH_CLOSED: {
      return { inputWidth: 0 };
    }
    default:
      return state;
  }
};
