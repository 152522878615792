import type { AppState } from '../../common/types';
import { fetchPricingPlansUrlSuccess } from '../actions/fetch-princing-plans-url';

type Action = ReturnType<typeof fetchPricingPlansUrlSuccess>;

export default function pricingPlansUrlReducer(
  state = {} as AppState,
  action: Action,
) {
  switch (action.type) {
    case fetchPricingPlansUrlSuccess.type:
      return action.payload ?? null;
    default:
      return state;
  }
}
