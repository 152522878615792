import {
  ABOVE_CONTENT_1,
  ABOVE_CONTENT_2,
  ABOVE_HEADER,
  BELOW_CONTENT_1,
  BELOW_CONTENT_2,
  PAGE_BOTTOM_1,
  PAGE_BOTTOM_2,
  PAGE_BOTTOM_3,
} from '../../../../constants/ooi-slots';

export const setPostIDInSlots = (
  postId: string | undefined,
  slotAPIFactory: Record<string, any>,
) =>
  postId &&
  [
    ABOVE_HEADER,
    ABOVE_CONTENT_1,
    ABOVE_CONTENT_2,
    BELOW_CONTENT_1,
    BELOW_CONTENT_2,
    PAGE_BOTTOM_1,
    PAGE_BOTTOM_2,
    PAGE_BOTTOM_3,
  ].forEach((slotId) => {
    const slot = slotAPIFactory.getSlotAPI(slotId);
    slot.postId = postId;
  });
