import { TRANSFORM_TYPE_FIT } from '@wix/communities-blog-universal/dist/src/constants/image';

const getImageFormat = (filename) => {
  const matches = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/i.exec(filename);
  return matches ? matches[0] : '.jpg';
};

const getImageUrl = (imageHost, filename, isAmp) => {
  const imgUrl = `https://${imageHost}/media/${filename}`;
  const ampImgUrl = `${imgUrl}/v1/${TRANSFORM_TYPE_FIT}/w_600,h_60,al_c,q_80/file${getImageFormat(
    filename,
  )}`;
  return isAmp ? ampImgUrl : imgUrl;
};

const getJsonSchemaPublisher = ({
  imageHost,
  isAmp,
  isSchemaEnabled,
  isWP,
  siteProperties,
}) => {
  if (isWP) {
    const filename = isAmp ? 'wix-logo.amp.png' : 'wix-logo.png';
    return {
      '@type': 'Organization',
      '@id': 'https://www.wix.com',
      name: 'Wix.com',
      logo: {
        '@type': 'ImageObject',
        url: `https://static.parastorage.com/unpkg-semver/communities-blog-statics/assets/${filename}`,
      },
    };
  }

  if (!siteProperties || !isSchemaEnabled) {
    return;
  }

  const { businessName, logo, siteDisplayName } = siteProperties;

  if (logo) {
    return {
      '@type': 'Organization',
      name: businessName || siteDisplayName,
      logo: {
        '@type': 'ImageObject',
        url: getImageUrl(imageHost, logo, isAmp),
      },
    };
  }
};

export default getJsonSchemaPublisher;
