import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  createPerformanceTracker,
  resetMetrics,
  urijs,
} from '@wix/communities-blog-client-common';
import { setRouterMatch } from '../router';
import createRequest from '../services/create-request';
import getEnvironment from '../services/get-environment';
import { getHttpClientFromFlowAPI } from '../services/get-http-client-from-flow-api';
import { validateLanguage } from '../services/validate-language';
import {
  addDebugState,
  addErrorState,
} from '../store/debug-state/debug-state-actions';
import { parseInstance } from '../store/instance-values/parse-instance';
import { getPathname } from '../store/location/get-pathname';

const REGEX_POST_PAGE_PREVIEW = /[0-9a-fA-F]+\/preview\/([^/]+)\/?/;

export const getPreviewInstance = (location) => {
  const match = location.path.join('/').match(REGEX_POST_PAGE_PREVIEW);
  return match ? match[1] : undefined;
};

export const getInstance = (wixCodeApi) => () =>
  wixCodeApi.site.getAppToken(BLOG_APP_ID);

export const resolveUser = (wixCodeApi, allowPreviewInstance) => {
  const user = wixCodeApi.user.currentUser;
  const isSeo = wixCodeApi.seo.isInSEO();
  const previewInstance =
    allowPreviewInstance && !isSeo && getPreviewInstance(wixCodeApi.location);
  if (previewInstance) {
    user.loggedIn = true;
    user.instance = previewInstance;
    user.id = parseInstance(previewInstance).uid;
  } else {
    user.instance = getInstance(wixCodeApi)();
  }
  return user;
};

const noop = () => {};

export const createLogger = (isDebug, isProduction) =>
  !isProduction || isDebug ? console.log : noop;

export const onLocationChange = ({ wixCodeApi, callback }) => {
  if (!wixCodeApi.location.onChange) {
    return;
  }
  let currentPathname = wixCodeApi.location.path.join('/');

  wixCodeApi.location.onChange((args) => {
    const pathname = args.path.join('/');
    if (pathname === currentPathname) {
      return;
    }
    currentPathname = pathname;
    callback(args);
  });
};

export const doRouting = ({ router, pathname, store, isInitialLoad = true }) =>
  isInitialLoad
    ? router
        .resolve(pathname)
        .then((match) => store.dispatch(setRouterMatch(match)))
    : router.match(pathname);

export const createRequestWithBaseUrl =
  ({ wixCodeApi, getStore, bundleName, flowAPI }) =>
  (baseUrl) => {
    const { isDebug, isSSR } = getEnvironment(wixCodeApi);
    return createRequest({
      baseUrl,
      getInstance: getInstance(wixCodeApi),
      locale: validateLanguage(wixCodeApi.site.language),
      trackError: getStore
        ? (error) => getStore().dispatch(addErrorState(error))
        : undefined,
      logResponse:
        isDebug && getStore
          ? (response) => getStore().dispatch(addDebugState(response))
          : undefined,
      petriOvr: wixCodeApi.location.query.petri_ovr,
      siteRevision: wixCodeApi.site.revision,
      isDebug,
      performanceTracker: createPerformanceTracker(bundleName, {
        isDebug,
        isSSR,
      }),
      httpClient: getHttpClientFromFlowAPI(flowAPI),
      isSSR,
    });
  };

export const appendOriginInBackend = ({ wixCodeApi, baseUrl }) => {
  const origin = urijs(wixCodeApi.location.baseUrl).origin();
  const isBackend = wixCodeApi.window.rendering.env === 'backend';
  return isBackend ? `${origin}${baseUrl}` : baseUrl;
};

export const createControllerId = () => new Date().getTime();

export const getSectionPathname = ({ wixCodeApi, sectionUrl }) => {
  return getPathname(wixCodeApi.location, sectionUrl);
};

export const handleLocationChange = ({
  wixCodeApi,
  store,
  log,
  bundleName,
  router,
  sectionUrl,
}) => {
  onLocationChange({
    wixCodeApi,
    callback: ({ path }) => {
      log('navigated to new path', path);
      resetMetrics(bundleName);
      doRouting({
        store,
        router,
        pathname: getSectionPathname({ wixCodeApi, sectionUrl }),
        isInitialLoad: false,
      });
    },
  });
};
