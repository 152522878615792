import { compact } from 'lodash';
import { listDemoPosts } from '@wix/ambassador-blog-v3-post/http';
import { Field } from '@wix/ambassador-blog-v3-post/types';
import {
  BLOG_HEADER_TOTAL_RESULTS,
  getCurrentUser,
} from '@wix/communities-blog-client-common';

import { getLocale, getQueryLocale } from '../selectors/locale-selectors';
import { fetchCurrentUser } from '../store/auth/fetch-current-user';
import { normalizePostV3 } from './post-utils';
import { encodeURISlug } from './slug';

export const getDemoPosts = async ({
  getState,
  dispatch,
  httpClient,
  aggregatorRequest,
  wixCodeApi,
  query,
}) => {
  try {
    const isFiltered = Boolean(
      query.featuredOnly || query.categoryId || query.tagId,
    );

    const [hasPosts, response] = await Promise.all([
      isFiltered &&
        getHasRealPosts({ aggregatorRequest, language: query.language }),
      fetchDemoPosts({
        getState,
        httpClient,
        query,
      }),
      !getCurrentUser(getState()) &&
        dispatch(fetchCurrentUser(wixCodeApi.user.currentUser.instance)),
    ]);

    return isFiltered && hasPosts ? toResponse([]) : response;
  } catch (ex) {
    console.error(ex);
    return toResponse([]);
  }
};

const fetchDemoPosts = async ({
  getState,
  httpClient,
  query: {
    page = 1,
    pageSize = 6,
    slugs,
    excludeIds,
    language,
    featuredOnly,
    categoryId,
    tagId,
  },
}) => {
  const state = getState();

  if (!language) {
    language = getQueryLocale(state) || getLocale(state);
  }

  const filters = !slugs
    ? {
        paging: {
          offset: (page - 1) * pageSize,
          limit: pageSize,
        },
        ...(featuredOnly ? { featured: true } : {}),
        ...(categoryId ? { categoryIds: [categoryId] } : {}),
        ...(tagId ? { tagIds: [tagId] } : {}),
      }
    : {};

  const { data } = await httpClient.request(
    listDemoPosts({
      language,
      fieldsToInclude: [Field.CONTENT],
      ...filters,
    }),
  );

  const posts = applyFilters({ slugs, excludeIds }, data.posts);
  return toResponse(
    normalizePosts({ posts, getState }),
    data.metaData?.total ?? posts.length,
  );
};

const applyFilters = ({ slugs, excludeIds, categoryId, tagId }, posts) => {
  const filters = compact([
    Array.isArray(slugs) && ((x) => slugs.includes(encodeURISlug(x.slug))),
    categoryId && ((x) => x.categoryIds.includes(categoryId)),
    tagId && ((x) => x.tagIds.includes(tagId)),
    Array.isArray(excludeIds) && ((x) => !excludeIds.includes(x.id)),
  ]);
  return filters.reduce((acc, predicate) => acc.filter(predicate), posts);
};

const normalizePosts = ({ posts, getState }) => {
  const firstPublishedDate = new Date();
  firstPublishedDate.setMinutes(firstPublishedDate.getMinutes() - 1);
  const lastPublishedDate = new Date();
  lastPublishedDate.setHours(firstPublishedDate.getHours() - 24);
  const owner = getCurrentUser(getState()) || {};

  return posts.map((post) => {
    post.firstPublishedDate = firstPublishedDate.toISOString();
    post.lastPublishedDate = lastPublishedDate.toISOString();
    post.owner = owner;
    if (!post.coverImage) {
      post.status = 'published';
      return normalizePostV3(post);
    }
    return post;
  });
};

const toResponse = (posts, total = 0) => ({
  posts,
  headers: { [BLOG_HEADER_TOTAL_RESULTS.toLowerCase()]: total },
});

const getHasRealPosts = async ({ language, aggregatorRequest }) => {
  const { posts } = await aggregatorRequest(
    `/v1/post-list-widget/render-model?${[
      'postLimit=1',
      language && `language=${language}`,
      'fields=posts',
    ]
      .filter(Boolean)
      .join('&')}`,
    { throwOnInvalidJson: true },
  );
  return posts.body.length > 0;
};
