import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GLOBALS } from '@wix/communities-blog-client-common';
import {
  createRequestWithBaseUrl,
  appendOriginInBackend,
} from '../controller/helpers';
import type { IWixCodeApi } from '../controller/wix-code-api';
import { AppStore } from '../types';
import getEnvironment from './get-environment';

interface CreateRequestsParams {
  wixCodeApi: IWixCodeApi;
  bundleName: string;
  getStore: () => AppStore;
  flowAPI: ControllerFlowAPI;
}

export default function createRequests({
  wixCodeApi,
  bundleName,
  getStore,
  flowAPI,
}: CreateRequestsParams) {
  const { isEditor, isPreview } = getEnvironment(wixCodeApi);
  const createRequest = createRequestWithBaseUrl({
    wixCodeApi,
    getStore,
    bundleName,
    flowAPI,
  });

  const blogApiBaseUrl =
    isEditor || isPreview
      ? GLOBALS.API_BASE_URL_CLIENT
      : `${wixCodeApi.location.baseUrl}${GLOBALS.API_BASE_URL_CLIENT}`;
  const request = createRequest(blogApiBaseUrl);

  const platformizedRequestUrl = appendOriginInBackend({
    wixCodeApi,
    baseUrl: GLOBALS.API_PLATFORMIZED_BASE_URL_CLIENT,
  });
  const platformizedRequest = createRequest(platformizedRequestUrl);

  const paywallRequestUrl = appendOriginInBackend({
    wixCodeApi,
    baseUrl: GLOBALS.API_PAYWALL_BASE_URL,
  });
  const paywallRequest = createRequest(paywallRequestUrl);

  const aggregatorRequestUrl = appendOriginInBackend({
    wixCodeApi,
    baseUrl: GLOBALS.API_AGGREGATOR_BASE_URL,
  });
  const aggregatorRequest = createRequest(aggregatorRequestUrl);

  return {
    blogApiBaseUrl,
    request,
    platformizedRequest,
    paywallRequest,
    aggregatorRequest,
  };
}
