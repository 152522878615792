import { APP_LOADED_RECORDED } from './app-loaded-actions';

export function appLoaded(state = { hasReported: false }, action) {
  switch (action.type) {
    case APP_LOADED_RECORDED:
      return {
        ...state,
        hasReported: true,
      };

    default:
      return state;
  }
}
