import { SET_FEED_SEO_TAGS } from '../actions/set-feed-seo-tags';

export default function feedSEOTags(state = null, action) {
  switch (action.type) {
    case SET_FEED_SEO_TAGS:
      return action.payload;
    default:
      return state;
  }
}
