import {
  ENTITY_TYPE_MANAGE_POSTS_DRAFTS,
  ENTITY_TYPE_MANAGE_POSTS_PUBLISHED,
  ENTITY_TYPE_POSTS,
  POST_ENTITY_TYPES,
  resolveId,
} from '@wix/communities-blog-client-common';
import { CREATE_POST_DRAFT_SUCCESS } from '../../actions/create-post-draft-types';
import { DELETE_POST_SUCCESS } from '../../actions/delete-post';
import {
  FETCH_ARCHIVE_REQUEST,
  FETCH_ARCHIVE_SUCCESS,
} from '../../actions/fetch-archive';
import {
  FETCH_CATEGORY_POSTS_REQUEST,
  FETCH_CATEGORY_POSTS_SUCCESS,
} from '../../actions/fetch-category-posts';
import {
  FETCH_FEED_POSTS_REQUEST,
  FETCH_FEED_POSTS_SUCCESS,
} from '../../actions/fetch-feed-posts';
import {
  FETCH_HASHTAG_POSTS_REQUEST,
  FETCH_HASHTAG_POSTS_SUCCESS,
} from '../../actions/fetch-hashtag-posts';
import {
  FETCH_TAG_POSTS_REQUEST,
  FETCH_TAG_POSTS_SUCCESS,
} from '../../actions/fetch-tag-posts';
import { PUBLISH_POST_SUCCESS } from '../../actions/publish-post-types';
import { PAGINATION_CLEAR_COLLECTION } from './pagination-actions';
import {
  INITIAL_STATE,
  withCurrentPage,
  withEntities,
  withEntityInHead,
  withoutEntities,
  withoutEntity,
} from './pagination-old-utils';

const paginationReducerOld = (
  state = INITIAL_STATE,
  { type, payload, meta = {} } = {},
) => {
  switch (type) {
    // Pagination middleware
    case PAGINATION_CLEAR_COLLECTION:
      return {
        ...state,
        ...withoutEntities({
          state,
          entityType: payload.entityType,
        }),
      };
    // POSTS
    case FETCH_FEED_POSTS_REQUEST:
    case FETCH_CATEGORY_POSTS_REQUEST:
    case FETCH_TAG_POSTS_REQUEST:
    case FETCH_ARCHIVE_REQUEST:
    case FETCH_HASHTAG_POSTS_REQUEST:
      return {
        ...state,
        ...withCurrentPage({
          state,
          entityType: payload.entityType || ENTITY_TYPE_POSTS,
          currentPage: payload.page,
        }),
      };
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_SUCCESS:
    case FETCH_ARCHIVE_SUCCESS:
    case FETCH_HASHTAG_POSTS_SUCCESS:
      return {
        ...state,
        ...withEntities({
          state,
          entities: payload,
          ...meta,
          entityType: meta.entityType || ENTITY_TYPE_POSTS,
        }),
      };
    case CREATE_POST_DRAFT_SUCCESS:
      return {
        ...state,
        ...withEntityInHead({
          state,
          entityType: ENTITY_TYPE_MANAGE_POSTS_DRAFTS,
          entityId: resolveId(payload),
        }),
      };
    case PUBLISH_POST_SUCCESS:
      return {
        ...state,
        ...withoutEntity({
          state,
          entityTypes: [ENTITY_TYPE_MANAGE_POSTS_DRAFTS],
          entityId: resolveId(payload),
        }),
        ...withEntityInHead({
          state,
          entityType: ENTITY_TYPE_MANAGE_POSTS_PUBLISHED,
          entityId: resolveId(payload),
        }),
      };
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        ...withoutEntity({
          state,
          entityTypes: POST_ENTITY_TYPES,
          entityId: payload,
        }),
      };

    default:
      return state;
  }
};

export default paginationReducerOld;
