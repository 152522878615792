import UrlPattern from 'url-pattern';
import { ROUTE_404 } from '../../post-page/constants/routes';

const FALLBACK_ROUTE = '*';
const MATCH_RETRY_COUNT = 5;

const URL_PATTERN_OPTIONS = { segmentValueCharset: 'a-zA-Z0-9-_~ %.' };
const matchRoute = (route, path) =>
  new UrlPattern(route, URL_PATTERN_OPTIONS).match(path);

const decodeParams = (match) =>
  Object.keys(match).reduce((acc, cur) => {
    acc[cur] = decodeURIComponent(match[cur]);
    return acc;
  }, {});

const normalize = (pathname) => {
  if (pathname === '' || pathname === '/') {
    return '/';
  }
  return pathname.replace(/\/+$/g, '');
};

export class Router {
  static resultIdentifier = '@@RouterResult';

  constructor() {
    this.routes = new Map();
    this.preFetchResult = new Map();
    this.matchListeners = [];
  }

  add = (route, callback, options = {}) => {
    this.routes.set(route, { callback, options });
  };

  fallback = (redirect) => {
    this.routes.set(FALLBACK_ROUTE, {
      route: redirect,
      ...this.routes.get(redirect),
    });
  };

  onMatch = (callback) => this.matchListeners.push(callback);

  triggerMatch = (result) => this.matchListeners.forEach((cb) => cb(result));

  preFetch = async (path) => {
    const {
      route,
      pathname,
      params,
      callback,
      options = {},
    } = (await this.matchPath({ pathname: path })) || {};
    if (route && callback && options.preFetch) {
      const preFetchResult = Promise.resolve(
        callback({ pathname, route, params }, () => {}, { preFetch: true }),
      );
      this.preFetchResult.set(path, {
        route,
        pathname,
        params,
        callback,
        preFetchResult,
      });
    }
  };

  resolve = (path) => {
    const preFetchRoute = this.preFetchResult.get(path);
    preFetchRoute && this.preFetchResult.delete(path);
    return this.match(path, [], preFetchRoute);
  };

  addCustomRouteHandler = (handler) => {
    this.customRouteHandler = handler;
  };

  match = async (path, prevMatches = [], preFetchRoute) => {
    if (prevMatches.length >= MATCH_RETRY_COUNT) {
      throw new Error('too many redirects');
    }

    const { route, pathname, params, callback, preFetchResult } =
      preFetchRoute ||
      (await this.matchPath({ pathname: path, prevMatches })) ||
      {};

    if (!route) {
      throw new Error('failed to match route');
    }

    const routeObj = {
      pathname: decodeURIComponent(pathname),
      encodedPathname: pathname,
      route,
      params,
      prevMatches,
      [Router.resultIdentifier]: true,
    };

    this.triggerMatch(routeObj);
    return this.resolveRoute(routeObj, callback, prevMatches, preFetchResult);
  };

  matchPath = async ({
    pathname,
    matchCustomRoute = true,
    prevMatches = [],
  }) => {
    const normalizedPathname = normalize(pathname);

    for (let [route, { callback, options }] of this.routes) {
      const match = matchRoute(route, normalizedPathname);

      const isLastRoute = route === FALLBACK_ROUTE || route === ROUTE_404;

      if (
        matchCustomRoute &&
        this.customRouteHandler &&
        isLastRoute &&
        prevMatches.length < MATCH_RETRY_COUNT - 1
      ) {
        const customRoute = await this.customRouteHandler();
        if (customRoute) {
          return this.matchPath({
            pathname: customRoute,
            matchCustomRoute: false,
          });
        }
      }

      if (match) {
        if (route === FALLBACK_ROUTE) {
          ({ route, callback, options } = this.routes.get(FALLBACK_ROUTE));
        }

        return {
          pathname,
          route,
          params: decodeParams(match),
          callback,
          options,
        };
      }
    }
  };

  resolveRoute = async (
    routeObj,
    callback,
    prevMatches = [],
    preFetchResult,
  ) => {
    if (!callback) {
      return routeObj;
    }
    const redirect = (path) => this.match(path, prevMatches.concat(routeObj));
    const val = await Promise.resolve(
      callback(routeObj, redirect, { preFetchResult }),
    );
    return val && val[Router.resultIdentifier] ? val : routeObj;
  };
}
