import { get } from 'lodash';
import { createAction } from '@wix/communities-blog-client-common';

export const USER_JOIN_COMMUNITY_SUCCESS = 'USER_JOIN_COMMUNITY_SUCCESS';
const userJoinCommunitySuccess = createAction(USER_JOIN_COMMUNITY_SUCCESS);

const POPUP_URL = `https://apps.wix.com/_api/members-area/public/modal/join-community`;

export const showJoinCommunityPopup =
  () =>
  (dispatch, getState, { wixCodeApi, compId }) => {
    const onClose =
      (callback) =>
      ({ message }) =>
        callback(get(message, 'payload.inCommunity', false));
    const updateCurrentUserState = (hasJoined) =>
      hasJoined && dispatch(userJoinCommunitySuccess());

    wixCodeApi.window
      .openPersistentPopup(
        POPUP_URL,
        {
          theme: 'BARE',
          width: '100%',
          height: '100%',
          position: {
            origin: 'FIXED',
            placement: 'CENTER',
            x: 0,
            y: 0,
          },
        },
        compId,
      )
      .then(onClose(updateCurrentUserState))
      .catch(onClose(updateCurrentUserState));
  };
