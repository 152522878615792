import { compact } from 'lodash';
import { combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { appUouBiMiddleware } from '../../common/bi-events/init-middleware';
import type { IWixCodeApi } from '../../common/controller/wix-code-api';
import commonReducers from '../../common/reducers';
import createRequests from '../../common/services/create-requests';
import createStore from '../../common/services/create-store';
import type {
  AppParams,
  AppStore,
  FedopsLogger,
  FlowAPI,
  PlatformAPIs,
} from '../../common/types';
import { eventMap } from '../bi-events/uou-events';
import tagCloudWidgetReducer from '../store/tag-cloud.reducer';

type CreateReduxStoreParams = {
  appParams: AppParams;
  wixCodeApi: IWixCodeApi;
  compId: string;
  fedopsLogger: FedopsLogger;
  isEditor: boolean;
  platformAPIs: PlatformAPIs;
  bundleName: string;
  flowAPI: FlowAPI;
};

export function createReduxStore({
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  isEditor,
  platformAPIs,
  bundleName,
  flowAPI,
}: CreateReduxStoreParams) {
  // eslint-disable-next-line prefer-const
  let store: AppStore;

  const { request, platformizedRequest, aggregatorRequest } = createRequests({
    wixCodeApi,
    bundleName,
    getStore: () => store,
    flowAPI,
  });

  const biMiddleware =
    !isEditor &&
    appUouBiMiddleware({
      platformAPIs,
      instanceId: appParams.instanceId,
      eventMap,
    });

  const middleware = compact([
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      aggregatorRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
      httpClient: flowAPI.httpClient,
    }),
    biMiddleware,
  ]);

  const reducers = combineReducers({
    ...commonReducers,
    tagCloudWidget: tagCloudWidgetReducer,
  });

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return store;
}
