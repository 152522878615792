import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import {
  createAction,
  CUSTOM_ROUTE_PLACEHOLDERS,
} from '@wix/communities-blog-client-common';

export const SET_CUSTOM_ROUTES = 'customRoutes/SET';
const setCustomRoutesAction = createAction(SET_CUSTOM_ROUTES);

export const setCustomRoutes =
  () =>
  async (dispatch, getState, { wixCodeApi }) => {
    const customRoutes = {};
    const routes = {
      [UrlMappingsKeys.BLOG_POST]: { slug: CUSTOM_ROUTE_PLACEHOLDERS.postSlug },
      [UrlMappingsKeys.BLOG_POST_EDIT]: {
        slug: CUSTOM_ROUTE_PLACEHOLDERS.postId,
      },
    };

    await Promise.all(
      Object.keys(routes).map(async (key) => {
        customRoutes[key] = await wixCodeApi.location.buildCustomizedUrl(
          key,
          routes[key],
        );
      }),
    );

    dispatch(setCustomRoutesAction(customRoutes));
  };
