import {
  ARCHIVE_PARAMS,
  ARCHIVE_PARAMS_MOBILE,
} from '@wix/communities-blog-client-common';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';

import { getIsMobileSettingEnabled } from '../../common/selectors/mobile-settings-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';

const {
  isMobileDisplaySettingsEnabled,
  isMobileDesignSettingsEnabled,
  isMobileLayoutSettingsEnabled,
} = ARCHIVE_PARAMS_MOBILE;

const getUseMobileSettings = (state, wixParamPath) =>
  getIsMobile(state) && getIsMobileSettingEnabled(state, wixParamPath);

const getArchiveDisplayParams = (state) =>
  getUseMobileSettings(state, isMobileDisplaySettingsEnabled.appSettingsPath)
    ? ARCHIVE_PARAMS_MOBILE
    : ARCHIVE_PARAMS;

export const getUseMobileDesingSettings = (state) =>
  getUseMobileSettings(state, isMobileDesignSettingsEnabled.appSettingsPath);

export const getUseMobileLayoutSettings = (state) =>
  getUseMobileSettings(state, isMobileLayoutSettingsEnabled.appSettingsPath);

export const getShowNewestPostsFirst = (state) => {
  const { showNewestPostsFirst } = getArchiveDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: showNewestPostsFirst.appSettingsPath,
    fallback: showNewestPostsFirst.defaultValue,
  });
};

export const getShowPostCount = (state) => {
  const { showPostCount } = getArchiveDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: showPostCount.appSettingsPath,
    fallback: showPostCount.defaultValue,
  });
};

export const getMonthsDisplayLimit = (state) => {
  const { monthsDisplayLimit } = getArchiveDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: monthsDisplayLimit.appSettingsPath,
    fallback: monthsDisplayLimit.defaultValue,
  });
};
