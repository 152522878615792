import { likeClick } from '@wix/bi-logger-blog-app-uou/v2';
import type { LikeStatusChangeEvent } from '../../blocks/pub-sub';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import { isPublished } from '../../external/common/bi-events/event-helpers';
import { NormalizedPost } from '../../external/common/types';
import { selectors } from './elements';
import type { ControllerParams } from './types';

export type PostCounters = {
  id: string;
  likeCount: number;
  viewCount: number;
  commentsCount: number;
  isLiked: boolean;
  post: NormalizedPost | undefined;
};

export class CounterController {
  private state: PostCounters = {
    id: '',
    isLiked: false,
    likeCount: 0,
    viewCount: 0,
    commentsCount: 0,
    post: undefined,
  };

  private readonly ui = {
    likeStatesContainer: this.context.$w('#statesContainer'),
    likeCountDefault: this.context.$w(selectors.likesDefaultNumber),
    likeButtonEmpty: this.context.$w(selectors.likesButtonEmpty),
    likeCountFilled: this.context.$w(selectors.likesFilledNumber),
    likeButtonFilled: this.context.$w(selectors.likesButtonFilled),
    viewsCount: this.context.$w(selectors.views),
    viewsCountContainer: this.context.$w(selectors.viewsContainer),
    commentsCount: this.context.$w(selectors.comments),
    commentsContainer: this.context.$w(selectors.commentsContainer),
  };

  private readonly facade = new BlocksWidgetFacade(
    this.context.flowAPI,
    this.context.controllerConfig.wixCodeApi,
  );

  constructor(private readonly context: ControllerParams) {
    this.context.appData?.subjects.likeStatusChanged.subscribe((event: any) => {
      this.handleLikeChangeEvent(event.data);
    });
  }

  initialize = (model: PostPageRenderModel) => {
    if (!model.post.id) {
      throw new Error('No post id provided');
    }

    const commentsCount = this.context.flowAPI.environment.isEditor
      ? 5
      : model.metadata.totalComments ?? 0;

    this.setState({
      id: model.post.id,
      likeCount: model.metadata.likeCount || 0,
      viewCount: model.metadata.viewCount || 0,
      commentsCount,
      isLiked: model.metadata.isLiked || false,
      post: model.post,
    });

    this.setAccessibilityLabels();

    this.ui.likeButtonEmpty.onClick(this.handleLikeClick);
    this.ui.likeButtonFilled.onClick(this.handleLikeClick);
  };

  private async setState(nextState: CounterController['state']) {
    this.state = nextState;
    await this.applyStateToUi();
  }

  private handleLikeClick = async () => {
    if (!this.state.id) {
      throw new Error('No id provided');
    }

    const nextLikeState = !this.state.isLiked;
    const nextLikeCount = this.state.likeCount + (nextLikeState ? 1 : -1);

    this.context.flowAPI.bi?.report(
      likeClick({
        flag: nextLikeState,
        likes_count: nextLikeCount,
        is_published: Boolean(isPublished(this.state.post)),
        post_stable_id: this.state.id,
      }),
    );

    if (nextLikeState) {
      await this.facade.likePost(this.state.id);
    } else {
      await this.facade.dislikePost(this.state.id);
    }

    this.setState({
      ...this.state,
      isLiked: nextLikeState,
      likeCount: nextLikeCount,
    });

    this.context.appData?.subjects.likeStatusChanged.next({
      isLiked: nextLikeState,
      likeCount: nextLikeCount,
    });
  };

  private async applyStateToUi() {
    const multiStateBoxState = this.state.isLiked ? 'filled' : 'default';

    this.ui.likeStatesContainer.changeState(multiStateBoxState);

    for (const el of [this.ui.likeCountFilled, this.ui.likeCountDefault]) {
      el.text = String(this.state.likeCount);
    }

    this.ui.viewsCount.text = String(this.state.viewCount);
    this.ui.commentsCount.text = String(this.state.commentsCount);
    this.setAccessibilityLabels();
  }

  private handleLikeChangeEvent(payload: LikeStatusChangeEvent) {
    this.setState({
      ...this.state,
      likeCount: payload.likeCount,
      isLiked: payload.isLiked,
    });
  }

  private setAccessibilityLabels() {
    const { t } = this.context.flowAPI.translations;
    const {
      viewsCountContainer,
      commentsContainer,
      likeButtonEmpty,
      likeButtonFilled,
    } = this.ui;

    viewsCountContainer.accessibility.ariaLabel = t('post.views.count', {
      count: this.state.viewCount,
    });

    commentsContainer.accessibility.ariaLabel = t('post.comments.count', {
      count: this.state.commentsCount,
    });

    for (const likeButton of [likeButtonFilled, likeButtonEmpty]) {
      likeButton.accessibility.ariaLabel = t('post.likes.count', {
        count: this.state.likeCount,
      });
    }
  }
}
