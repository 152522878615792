import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { createSlotVeloAPIFactory } from '@wix/widget-plugins-ooi/velo';
import {
  getAppConfig,
  NormalizedCategory,
  SECTION_CATEGORY,
} from '@wix/communities-blog-client-common';
import { ABOVE_POST_LIST } from '../../../../constants/ooi-slots';
import { navigateWithinBlog } from '../../../common/actions/navigate-within-blog';
import { pageOpened } from '../../../common/actions/page-opened';
import type { IWixCodeApi } from '../../../common/controller/wix-code-api';
import { isPostContentRequired } from '../../../common/selectors/layout-selectors';
import { getQueryLocale } from '../../../common/selectors/locale-selectors';
import {
  getIsValidPage,
  getLazyPaginationParams,
  getShowPagination,
} from '../../../common/selectors/pagination-selectors';
import { CATEGORY_PAGE } from '../../../common/services/detect-route';
import { generateCategorySEOTags } from '../../../common/services/generate-seo-tags/generate-category-seo-tags';
import { resolveCategorySlug } from '../../../common/services/slug';
import {
  getBasicParams,
  isSite,
  isSSR,
} from '../../../common/store/basic-params/basic-params-selectors';
import {
  getCategories,
  getCategoryBySlug,
  getCategoryByTranslationSlug,
} from '../../../common/store/categories/categories-selectors';
import { fetchProfileUrls } from '../../../common/store/profile-urls/profile-urls-actions';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { AppStore, RouteResolverFn } from '../../../common/types';
import { fetchCategoryFeedRenderModel } from '../../actions/fetch-feed-render-model';
import { ROUTE_404, RouteCategoryParams } from '../../constants/routes';

type Params = {
  store: AppStore;
  wixCodeApi: IWixCodeApi;
  controllerConfig: IWidgetControllerConfig;
};

export const createCategoryPageRouter = ({
  store,
  wixCodeApi,
  controllerConfig,
}: Params): RouteResolverFn<RouteCategoryParams> => {
  return async ({ params }, redirect, { preFetch, preFetchResult }) => {
    const page = parseInt(params.page || '1', 10);
    const slug = resolveCategorySlug(params);
    const basicParams = getBasicParams(store.getState());
    const categories = getCategories(store.getState());

    const initialCategoy = getCategoryBySlug(store.getState(), slug);

    if (initialCategoy) {
      const slotAPIFactory = createSlotVeloAPIFactory(controllerConfig);
      const abovePostListSlot = slotAPIFactory?.getSlotAPI(ABOVE_POST_LIST);

      if (abovePostListSlot) {
        abovePostListSlot.category = initialCategoy;
      }
    }

    const actions = preFetchResult || [
      store.dispatch(
        fetchCategoryFeedRenderModel({
          page,
          categoryOptions: { id: slug },
          pageSize: getLazyPaginationParams({
            state: store.getState(),
            section: SECTION_CATEGORY,
            page,
          })?.size,
          includeContent: isPostContentRequired(
            store.getState(),
            SECTION_CATEGORY,
          ),
          includeInitialPageData: categories.length === 0,
          language: basicParams.language,
        }),
      ),
    ];

    if (preFetch) {
      return Promise.all(actions);
    }

    if (preFetchResult) {
      await preFetchResult;
      store.dispatch(fetchProfileUrls());
    } else {
      await Promise.all(actions);
    }

    const i18nCategorySlug = getI18nCategorySlug(slug);

    if (i18nCategorySlug) {
      return store.dispatch(
        navigateWithinBlog(`/categories/${i18nCategorySlug}`),
      );
    }

    const category = getCategoryBySlug(store.getState(), slug);

    if (isInvalidPage() || !category) {
      return redirect(ROUTE_404);
    }

    await loadFeedSEOTagsIfMissing(page, category);

    trackPageOpen(category);
  };

  function getI18nCategorySlug(slug: string) {
    const category = getCategoryBySlug(store.getState(), slug);
    const lang = getQueryLocale(store.getState());

    if (lang && !category) {
      const i18nCategory = getCategoryByTranslationSlug(
        store.getState(),
        slug,
        lang,
      );

      return i18nCategory?.slug;
    }
  }

  function isInvalidPage() {
    const state = store.getState();
    const showPagination = getShowPagination(state, SECTION_CATEGORY);
    const isValidPage = getIsValidPage(state, SECTION_CATEGORY);
    return showPagination && !isValidPage;
  }

  async function loadFeedSEOTagsIfMissing(
    page: number,
    category: NormalizedCategory,
  ) {
    const state = store.getState();

    if (!isSite(state)) {
      return;
    }

    const categorySEOTags = await generateCategorySEOTags({
      appConfig: getAppConfig(state),
      sectionUrl: getSectionUrl(state),
      category,
      store,
      page,
      multilingual: wixCodeApi.window.multilingual,
      baseUrl: wixCodeApi.location.baseUrl,
    });

    wixCodeApi.seo.resetSEOTags();
    wixCodeApi.seo.renderSEOTags(categorySEOTags);
  }

  function trackPageOpen(category: NormalizedCategory) {
    const state = store.getState();

    if (isSSR(state)) {
      return;
    }

    store.dispatch(
      pageOpened({
        page: CATEGORY_PAGE,
        category,
      }),
    );
  }
};
