export const WAIT_FOR_ACTION = 'common/WAIT_FOR_ACTION';

export const waitForAction =
  (waitFor, action, invokeBeforeDispatch) => (dispatch) =>
    new Promise((resolve, reject) =>
      dispatch({
        type: WAIT_FOR_ACTION,
        payload: { action, resolve, reject, waitFor, invokeBeforeDispatch },
      }),
    );
