import {
  MODAL_TYPE_DELETE_POST,
  MODAL_TYPE_DISCARD_POST_SETTINGS,
  MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS,
  MODAL_TYPE_DISCARD_POST_DRAFT,
  MODAL_TYPE_POST_EDITOR,
  getCustomPostUrl,
} from '@wix/communities-blog-client-common';
import { discardPostDraft } from '../../../common/actions/discard-post-draft';
import {
  navigateWithinBlog,
  navigateToCustomPostUrl,
} from '../../../common/actions/navigate-within-blog';
import { handlePostDelete } from '../../../common/services/handle-post-delete';
import { discardPostSettingsModalResolver } from '../../../post-settings/containers/discard-post-settings-modal/discard-post-settings-modal-resolver';

export const modalResolverMapByType = {
  [MODAL_TYPE_DISCARD_POST_SETTINGS]: discardPostSettingsModalResolver,
  [MODAL_TYPE_DELETE_POST]: handlePostDelete({ permanently: false }),
  [MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS]: (postSlug) => (dispatch, getState) =>
    dispatch(
      navigateToCustomPostUrl(
        getCustomPostUrl(getState(), postSlug),
        `/${postSlug}`,
      ),
    ),
  [MODAL_TYPE_DISCARD_POST_DRAFT]: discardPostDraft,
  [MODAL_TYPE_POST_EDITOR]: (post) => (dispatch, getState) => {
    if (post.slug) {
      dispatch(
        navigateToCustomPostUrl(
          getCustomPostUrl(getState(), post.slug),
          `/${post.slug}`,
        ),
      );
    } else {
      dispatch(navigateWithinBlog('/'));
    }
  },
};
