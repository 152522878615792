import {
  RCE_FILE_UPLOADED,
  RCE_FILE_DOWNLOADED,
} from '@wix/communities-blog-client-common';

const uouFileEvents = {
  [RCE_FILE_UPLOADED]: (action) => {
    return {
      evid: 217,
      ...action.payload,
      eventMeta: {
        description: 'file uploaded event',
      },
    };
  },
  [RCE_FILE_DOWNLOADED]: (action) => {
    return {
      evid: 219,
      ...action.payload,
      eventMeta: {
        description: 'file downloaded event',
      },
    };
  },
};

export default uouFileEvents;
