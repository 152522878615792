import { createAction } from '@wix/communities-blog-client-common';

export const SET_IS_LOADED = 'isLoaded/SET';

export const setIsLoaded = createAction(
  SET_IS_LOADED,
  (entity, id, isLoaded) => ({
    entity,
    id,
    isLoaded,
  }),
);
