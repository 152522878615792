import { get } from 'lodash';
import { validateLanguage } from './validate-language';

export const getCurrentSiteLanguage = (wixCodeApi, flowAPI) => {
  return validateLanguage(
    getMultilingualLanguage(wixCodeApi) || flowAPI.environment.language,
  );
};

export const getMultilingualLanguage = (wixCodeApi) =>
  get(wixCodeApi, 'window.multilingual.currentLanguage');
