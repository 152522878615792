import postUtils from '@wix/communities-blog-universal/dist/src/post-utils';

export const isPublished = (post) => Number(postUtils.isPublished(post));
export const isEdited = (post) => Number(postUtils.isEdited(post));
export const categoryIds = (post) => JSON.stringify(post.categoryIds || []);
export const isDraftCreated = (post) =>
  post.createdDate === post.draft.editedDate;
export const paidPlanIds = (post) => (post.paidPlansGroupIds || []).join(',');
export const paidPlansHasAccess = (post) =>
  typeof post.canSeePaidContent === 'undefined'
    ? null
    : Number(post.canSeePaidContent);
