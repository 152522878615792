import { IControllerConfig } from '@wix/yoshi-flow-editor';
import { listCategories } from '@wix/ambassador-blog-v3-category/http';
import { ListCategoriesResponse } from '@wix/ambassador-blog-v3-category/types';
import { getTotalPosts } from '@wix/ambassador-blog-v3-post/http';
import { GetTotalPostsResponse } from '@wix/ambassador-blog-v3-post/types';
import {
  fetchTPASettings,
  getCategoryPath,
  TpaSettingsPayload,
} from '@wix/communities-blog-client-common';
import { getInstance } from '../../common/controller/helpers';
import { IWixCodeApi } from '../../common/controller/wix-code-api';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { initializeInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { AppParams, AppStore, FlowAPI, PlatformAPIs } from '../../common/types';
import { handleCategoryMenuWidgetResponse } from '../aggregated-category-menu/actions';

type InitializeStoreBaseDataParams = {
  wixCodeApi: IWixCodeApi;
  store: AppStore;
  language: string;
  platformAPIs: PlatformAPIs;
  config: IControllerConfig;
  bundleName: string;
  appParams: AppParams;
  flowAPI: FlowAPI;
};

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  appParams,
  flowAPI,
}: InitializeStoreBaseDataParams) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initializeInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());

  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  const response = await Promise.all([
    store.dispatch(
      setBasicParams({
        viewMode,
        language,
        biPageNumber: platformAPIs.bi?.pageNumber,
      }),
    ),
    store.dispatch(setAppConfig({ bundleName })),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(
      fetchTPASettings({
        language: wixCodeApi.window.multilingual.isEnabled
          ? getQueryLocale(store.getState()) || language
          : undefined,
        shouldUseFallbackOnError: true,
      }),
    ),
    flowAPI.httpClient.request(
      listCategories({ language: getQueryLocale(store.getState()) }),
    ),
    flowAPI.httpClient.request(getTotalPosts({})),
    store.dispatch(fetchTopology(instanceId)),
  ]);

  const categoryPath = getCategoryPath(store.getState());
  const listCategoriesResponse = response[4].data as ListCategoriesResponse;
  const getTotalPostsResponse = response[5]?.data as GetTotalPostsResponse;
  const settingsResponse = response[3] as TpaSettingsPayload;

  const defaultCategory = {
    id: 'latest_posts',
    path: '',
    display: {
      text: settingsResponse.settings?.allPostsFeedLabel,
      postCount: flowAPI.translations.t('category-link.post-count', {
        count: getTotalPostsResponse.total,
      }),
      a11yText: flowAPI.translations.t('category-link.a11y-post-count', {
        count: getTotalPostsResponse.total,
      }),
    },
  };

  const mappedCategories =
    listCategoriesResponse?.categories?.map((category) => ({
      id: category.id,
      path: `/${categoryPath}/${category.slug}`,
      display: {
        text: category.label,
        postCount: flowAPI.translations.t('category-link.post-count', {
          count: category.postCount,
        }),
        a11yText: flowAPI.translations.t('category-link.a11y-post-count', {
          count: category.postCount,
        }),
      },
    })) ?? [];

  const categoryMenu = {
    body: {
      areaLabel: flowAPI.translations.t('aria-label.category-menu'),
      categoryMenu: [defaultCategory, ...mappedCategories],
      emptyState: flowAPI.translations.t('empty-state.no-categories'),
    },
    status: 200,
  };

  await store.dispatch(handleCategoryMenuWidgetResponse(categoryMenu));
}

export {
  initializeActions,
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';
