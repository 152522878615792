import { trimEnd } from 'lodash';
import memoizeOne from 'memoize-one';
import { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import {
  createPageUrl,
  SECTION_BLOG_PAGE,
} from '@wix/communities-blog-client-common';
import type { HandleNavigationFn } from '../containers/pagination/create-page-link';

export const createArchivePageUrl = (
  sectionUrl: string,
  year: number,
  month: number,
  page: number,
  multilingualQueryParam: string,
) =>
  createPageUrl(
    page,
    `${trimEnd(
      sectionUrl,
      '/',
    )}/archive/${year}/${month}${multilingualQueryParam}`,
  );

export const createHashtagPageUrl = (
  sectionUrl: string,
  hashtag: string,
  page: number,
  multilingualQueryParam: string,
) =>
  createPageUrl(
    page,
    `${trimEnd(sectionUrl, '/')}/hashtags/${hashtag}${multilingualQueryParam}`,
  );

export const createTagPageUrl = (
  tag: BlogTag,
  page: number,
  multilingualQueryParam: string,
) =>
  createPageUrl(
    page,
    `${tag.url?.base}${tag.url?.path}${multilingualQueryParam}`,
  );

export const handleNavigation = memoizeOne(
  (path: string, isSite: boolean): HandleNavigationFn =>
    ({ event, pageUrl, navigateInPreview }) => {
      if (!isSite) {
        event.preventDefault();
        navigateInPreview(pageUrl, SECTION_BLOG_PAGE);
      }
    },
);
