import { IWixAPI } from '@wix/yoshi-flow-editor';
import {
  SANTA_MEMBERS_APP_ID,
  BLOG_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import { isMemberAreaInstalled } from '../../../../common/members';
import { FeedPageThunkAction } from '../../../feed-page/types';
import { MY_POSTS_WIDGET_ID } from '../../constants/widgets';

export const SET_COMMUNITIES_CONTEXT = 'communitiesContext/SET';

const isMyPostsInstalled = (wixCodeApi: IWixAPI) =>
  wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: BLOG_APP_ID,
    sectionId: 'My Posts',
  });

export const setCommunitiesContext =
  (): FeedPageThunkAction =>
  async (dispatch, getState, { wixCodeApi }) => {
    const [memberAreaInstalled, myPostsInstalled] = await Promise.all([
      isMemberAreaInstalled(wixCodeApi),
      isMyPostsInstalled(wixCodeApi),
    ]);

    dispatch({
      type: SET_COMMUNITIES_CONTEXT,
      payload: {
        [SANTA_MEMBERS_APP_ID]: {
          isInstalled: memberAreaInstalled,
        },
        [MY_POSTS_WIDGET_ID]: {
          isInstalled: myPostsInstalled,
        },
      },
    });
  };
